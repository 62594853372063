<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-container class="d-flex flex-column align-center">
			<v-form ref="form">
				<CardReservationNumber :service="checkinModel" />
				<v-card-title
					class="secondary--text font-weight-bold d-flex flex-column align-center my-n2"
				>
					{{ t("title") }}
				</v-card-title>

				<v-row style="width:100%" dense class="justify-center">
					<v-col cols="12" class="mb-n2">
						<TextField
							:disabled="inputDisable"
							@click.native="openForm()"
							prepend-inner-icon="hail"
							:value="pickupName"
							:messages="t('form.pickup.message')"
							:title="`${t('form.pickup.location')}*`"
						/>
					</v-col>
					<v-col cols="12">
						<TextField
							:disabled="inputDisable"
							@click.native="openForm()"
							prepend-inner-icon="location_on"
							:value="dropoffName"
							:messages="t('form.dropOff.message')"
							:title="`${t('form.dropOff.location')}*`"
						/>
					</v-col>
				</v-row>
			</v-form>
		</v-container>

		<v-container class="mb-3">
			<LayoutBtn
				:edition-locked="inputDisable"
				:edit="$t('global.btnEdit')"
				@click-edit="openForm()"
				:disabledConfirm="!isValid"
				:confirm="$t('global.btnConfirm')"
				@click-confirm="confirm()"
			/>
		</v-container>
    <DialogLocationInvalid v-model="invalidLocation" errorType="invalid" />
	</div>
</template>

<script>
import TextField from "@/components/field/TextField";
import LayoutBtn from "@/layouts/LayoutBtn";
import CardReservationNumber from "@/components/card/CardReservationNumber";

import { mapActions, mapState, mapGetters } from "vuex";
import { mapFields } from "@/helpers/mapFields";
import { calculateDistanceAndTime } from "@/helpers/utils";
import DialogLocationInvalid from "@/components/dialog/DialogLocationInvalid"

export default {
	components: {
		TextField,
		LayoutBtn,
		CardReservationNumber,
    DialogLocationInvalid
	},
	data: () => ({
		isValid: false,
    invalidLocation: false
	}),
	computed: {
		...mapFields({
			fields: ["travelTime", "travelLength"],
			base: "checkinModel",
			mutation: "SET_UPDATE_MODEL",
			module: "service"
		}),
		...mapGetters("service", [
			"existAirport",
			"getBasePath",
			"inputDisable"
		]),
		...mapState("service", ["checkinModel"]),
		pickupName: vm => vm.checkinModel?.pickup?.name || null,
		dropoffName: vm => vm.checkinModel?.dropoff?.name || null
	},
	watch: {
		checkinModel() {
			this.init();
		}
	},
	async mounted() {
		if (this.checkinModel.checkinStatus == "pending") {
			await this._updateService({ checkinStatus: "started" });
		}
		this.init();
		this.calculateDistance();
	},
	methods: {
		...mapActions("service", ["_updateService"]),
		init() {
			setTimeout(() => (this.isValid = this.$refs.form?.validate()), 500);
		},
		openForm() {
			if (this.inputDisable) {
        return (this.$root.$children[0].locked = true);
      }
      if (!this.pickupName || !this.dropoffName) return this.invalidLocation = true
			this.$router.push(this.getBasePath("/form/location"));
		},
		async calculateDistance() {
			try {
				const { time, length } = await calculateDistanceAndTime(
					this.checkinModel
				);
				this.travelTime = time;
				this.travelLength = length;
			} catch (error) {
				console.log(error);
			}
		},
		confirm() {
			this._updateService({
				pickup: this.checkinModel.pickup,
				dropoff: this.checkinModel.dropoff,
				travelTime: Number(this.checkinModel.travelTime),
				travelLength: Number(this.checkinModel.travelLength)
			}).then(() =>
				this.existAirport
					? this.$router.push(this.getBasePath("/reservation/flight"))
					: this.$router.push(
							this.getBasePath("/reservation/pschedule")
					  )
			);
		},
		t(key, options = {}) {
			return this.$t(`section.pickupDropOff.${key}`, options);
		}
	}
};
</script>
